import React from "react"
import { graphql } from "gatsby"
import { get } from "lodash"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import SignUpScreen from "../screens/SignUpScreen/SignUpScreen"
import { ROUTES } from "../navigation/routes"
import { ICMSSignUpFlow } from "../data/cms/signUpFlow"
import { getEnvKey } from "../utils/env"
import PageMetaTags, {
  CMSWebMetaSocial,
} from "../components/SiteMeta/PageMetaTags/PageMetaTags"

interface Data extends CMSWebMetaSocial {
  datoCmsWebSignUpFlowDev?: ICMSSignUpFlow
  datoCmsWebSignUpFlowStg?: ICMSSignUpFlow
  datoCmsWebSignUpFlowUat?: ICMSSignUpFlow
  datoCmsWebSignUpFlowPrd?: ICMSSignUpFlow
}

interface Props {
  data: Data
}

const SignUpPage: React.FC<Props> = props => {
  const contentKey = getEnvKey({
    devKey: "datoCmsWebSignUpFlowDev",
    stgKey: "datoCmsWebSignUpFlowStg",
    uatKey: "datoCmsWebSignUpFlowUat",
    prdKey: "datoCmsWebSignUpFlowPrd",
    defaultKey: "datoCmsWebSignUpFlowUat",
  })
  const content: ICMSSignUpFlow = get(props, `data.${contentKey}`)
  return (
    <SiteWrapper darkTheme>
      <SiteLayout hideHeaderOptions>
        <PageMetaTags data={props} />
        <SignUpScreen basePath={ROUTES.signUp.path} content={content} />
      </SiteLayout>
    </SiteWrapper>
  )
}

export default SignUpPage

export const query = graphql`
  query SignUpScreenQuery(
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    datoCmsWebSignUpFlowDev @include(if: $devEnv) {
      ...WebSignUpFlowDev
    }
    datoCmsWebSignUpFlowStg @include(if: $stgEnv) {
      ...WebSignUpFlowStg
    }
    datoCmsWebSignUpFlowUat @include(if: $uatEnv) {
      ...WebSignUpFlowUat
    }
    datoCmsWebSignUpFlowPrd @include(if: $prdEnv) {
      ...WebSignUpFlowPrd
    }
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
      screenMetaTags: signUpScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
      screenMetaTags: signUpScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
      screenMetaTags: signUpScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
      screenMetaTags: signUpScreenMetaTags {
        ...PageMeta
      }
    }
  }
`
